<template>
    <div id="container">
        <div id="image"></div>
        <div id="content">
            <div id="logo">
                <b-col cols="12">
                    <b-row>
                        <b-col cols="12" class="text-center">
                            <img src="../assets/favicon_kalkin.png" />
                        </b-col>
                    </b-row>
                    <b-row class="mt-4">
                        <b-col cols="12">
                            <b-form-input
                                v-model="username"
                                placeholder="Email ou nom d'utilisateur"
                            ></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="mt-4">
                        <b-col cols="12">
                            <b-form-input
                                type="password"
                                v-model="password"
                                placeholder="Mot de passe"
                            ></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="mt-4">
                        <b-col cols="12">
                            <b-button
                                class="custom-btn-secondary"
                                v-on:click="login"
                                >Se connecter</b-button
                            >
                        </b-col>
                    </b-row>
                </b-col>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data: function () {
        return {
            username: "",
            password: "",
        };
    },
    methods: {
        login: async function () {
            if (
                await this.$store.dispatch("user/login", {
                    username: this.username,
                    password: this.password,
                })
            ) {
                this.$router.push({ name: "Home" });
            }
        },
    },
    components: {},
};
</script>
<style scoped>
.custom-btn-secondary {
    color: white;
    background-color: #92bb3f;
    border: #92bb3f;
}
.custom-btn-secondary:hover {
    color: white;
    background-color: #a3d145;
    border: white;
}
#container {
    height: 100vh;
}
#container > div {
    height: 100%;
}
#image {
    width: 700px;
    background-image: url("../assets/connexion.png");
    background-size: cover;
    background-position: center;
    position: fixed;
    -webkit-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;
}
#content {
    width: calc(100% - 700px);
    margin-left: 700px;
    padding: 30px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#content div {
    flex: 1 1 auto;
}

@media screen and (max-width: 1000px) {
    #content {
        width: calc(100% - 20%);
        margin-left: 20%;
    }
    #image {
        width: 20%;
    }
}

@media screen and (max-width: 600px) {
    #content {
        width: 100%;
        margin-left: 0;
    }
    #image {
        width: 0;
    }
}
</style>
